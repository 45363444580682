import {DragDropModule} from '@angular/cdk/drag-drop';
import {Injector, NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {LongPressDirective} from '@app/02-core/directives/long-press.directive';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ChipLdapNamePipe,
  ChipNamePipe,
  EventStatusPipe,
  OptionLdapNamePipe,
  SafeUrlPipe,
  DateAddSecondsPipe,
  SecondsToTimePipe, DatesDiffSecondsPipe, BytesConvertPipe
} from '@app/02-core/pipes';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {VideoComponent} from '@app/02-core/components/video/video.component';
import {MatDividerModule} from '@angular/material/divider';
import {TimelineComponent} from '@app/02-core/components/timeline/timeline.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSliderModule} from '@angular/material/slider';
import {AfterViewInitDirective} from '@app/02-core/directives/after-view-init.directive';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonLoadingDirective} from '@app/02-core/directives/button-loading.directive';

const imports: any[] = [
];

const declarations: any[] = [
  SafeUrlPipe,
  SecondsToTimePipe,
  DateAddSecondsPipe,
  ChipNamePipe,
  EventStatusPipe,
  OptionLdapNamePipe,
  ChipLdapNamePipe,
  DatesDiffSecondsPipe,
  BytesConvertPipe,
  AfterViewInitDirective,
  VideoComponent,
  TimelineComponent,
  LongPressDirective,
  MatButtonLoadingDirective
];

const modules: any[] = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  PerfectScrollbarModule,
  MatIconModule,
  MatButtonModule,
  MatMenuModule,
  MatFormFieldModule,
  MatInputModule,
  LayoutModule,
  MatSlideToggleModule,
  DragDropModule,
  MatDialogModule,
  MatSelectModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatDividerModule,
  MatSliderModule,
  MatCheckboxModule,
  MatBadgeModule
];

const providers: any[] = [
];

/**
 * Core module, which only exports global imports
 */
@NgModule({
  declarations: [
    ...declarations,
  ],
  imports: [
    ...imports,
    ...modules
  ],
  providers: [
    ...providers,
  ],
  exports: [
    ...imports,
    ...modules,
    ...declarations
  ]
})
export class CoreModule {
  /**
   * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
   * This is good to prevent injecting the service as constructor parameter.
   */
  static injector: Injector;
  constructor(injector: Injector) {
    CoreModule.injector = injector;
  }
}
