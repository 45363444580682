import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatSliderChange} from '@angular/material/slider';
import Hls from 'hls.js';
import * as elementResizeDetectorMaker from 'element-resize-detector';
import {Platform} from '@angular/cdk/platform';

interface VideoControlsInterface {
  disabled?: boolean;
  play?: boolean;
  time?: boolean;
  mute?: boolean;
  volume?: boolean;
  fullscreen?: boolean;
  seek?: boolean;
  live?: boolean;
}

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, AfterViewChecked {

  public videoContainer: HTMLElement;
  public player: HTMLMediaElement;
  public isMetaLoaded = false;
  public isSeaking = false;
  public isPaused = true;
  public isMuted = false;
  public progress = 0;
  public buffer = 75;
  public duration = 0;
  public volume = 50;
  public currentTime = 0;
  public elapsedTime = '00:00:00';
  public durationTime = '00:00:00';
  public controlsVisible = true;
  public hidingControls: any = null;
  private hls: Hls;
  public isHlsSupported = false;
  public src = '';
  private erd: any;

  @ViewChild('video')
  set video(v: ElementRef) {
    this.player = v.nativeElement;
    this.player.addEventListener('play', this.pauseEvent);
    this.player.addEventListener('pause', this.pauseEvent);
    // this.player.addEventListener('click', this.toggleFullscreen);
    this.player.addEventListener('mouseenter', this.showControls);
    this.player.addEventListener('mouseleave', this.hideControls);
    this.player.addEventListener('timeupdate', this.timeEvent);
    this.player.addEventListener('loadedmetadata', this.metadataEvent);
    this.player.addEventListener('loadeddata', this.dataEvent);
    this.player.addEventListener('progress', this.bufferEvent);
    this.player.addEventListener('volumechange', this.volumeEvent);
    this.player.addEventListener('seeked', this.seekedEvent);
    this.playerLoaded.emit(this.player);
  }

  @Input() controls: VideoControlsInterface;
  @Input() source: string;
  @Input() hasLive: boolean;
  @Input() isLive: boolean;

  @Output() playerLoaded = new EventEmitter();
  @Output() dataLoaded = new EventEmitter();
  @Output() resized = new EventEmitter();

  @HostBinding('class.fakeFullscreen') isFakeFullscreen = false;
  @HostListener('dblclick') onClick(): void {
    this.toggleFullscreen();
  }

  constructor(
    private elementRef: ElementRef,
    private platform: Platform
  ) {
  }

  ngOnInit(): void {
    this.isHlsSupported = Hls.isSupported() && !this.platform.IOS && !this.platform.SAFARI;
    this.videoContainer = this.elementRef.nativeElement;
    this.controls = Object.assign({
      play: !this.controls.disabled,
      time: !this.controls.disabled,
      mute: !this.controls.disabled,
      volume: !this.controls.disabled,
      fullscreen: !this.controls.disabled,
      seek: !this.controls.disabled,
      live: !this.controls.disabled
    }, this.controls);
    this.erd = elementResizeDetectorMaker({
      strategy: 'scroll'
    });
    this.erd.listenTo(this.videoContainer, (element: any) => {
      this.resized.emit([element.offsetWidth, element.offsetHeight]);
    });
  }

  ngOnDestroy(): void {
    if (this.hls) {
      this.hls.destroy();
    }
    this.erd.removeAllListeners(this.videoContainer);
  }

  ngAfterViewChecked(): void {
  }

  ngAfterViewInit(): void {
    if (!!this.player.canPlayType) {
      this.player.controls = false;
    }
    if (this.isHlsSupported) {
      this.hls = new Hls({enableWorker: true, lowLatencyMode: true, autoStartLoad: true});
      this.hls.attachMedia(this.player);
      this.hls.on(Hls.Events.MEDIA_ATTACHED, (event, data) => {
        if (this.source && !this.isLive) {
          this.hls.loadSource(this.source);
        }
      });
      this.hls.on(Hls.Events.LEVEL_SWITCHED, () => {
      });
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            setTimeout(() => {
              this.hls.loadSource(this.source);
            }, 1000);
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            this.hls.recoverMediaError();
            // this.hls.startLoad(this.hls.liveSyncPosition + 1);
            break;
          default:
            // cannot recover
            this.hls.destroy();
            break;
        }
      });
    }
  }

  private getVideoTime(hls): void {
    console.log(hls);
    // const targetMedia = hls.playlists.media();
    //
    // const lastSegment = targetMedia.segments[0];
    //
    // const uri: string = lastSegment.uri;
    // const segmentTimestamp: number = +uri.substring(0, uri.length - 3);
    //
    // return new Date(segmentTimestamp);
  }

  initVideo = (): void => {
    this.duration = this.player.duration === Infinity ? 0 : this.player.duration;
    this.durationTime = this.formatTime(this.duration);
    this.player.volume = this.volume / 100;
    this.isMetaLoaded = true;
    this.showControls();
    this.hideControls();
  }

  dataEvent = (): void => {
    if (this.isHlsSupported) {
      this.initVideo();
    }
    this.dataLoaded.emit({
      duration: this.duration
    });
  }

  metadataEvent = (): void => {
    if (!this.isHlsSupported) {
      this.initVideo();
    }
  }

  bufferEvent = (): void => {
    if (this.player.buffered.length) {
      this.buffer = this.player.buffered.end(this.player.buffered.length - 1) / (this.duration / 100);
    }
  }

  seekedEvent = (): void => {
    this.finishSeeking();
  }

  changeSeek = (slider: MatSliderChange): void => {
    this.seeking(this.duration / 100 * slider.value);
  }

  private finishSeeking(): void {
    this.isSeaking = false;
    this.showControls();
  }

  private seeking(time: number): void {
    this.isSeaking = true;
    this.player.currentTime = time;
  }

  volumeEvent = (): void => {
    this.volume = this.player.volume * 100;
    this.isMuted = this.player.muted;
  }

  changeVolume = (slider: MatSliderChange): void => {
    this.player.volume = slider.value / 100;
  }

  pauseEvent = (): void => {
    this.isPaused = this.player.paused;
  }

  togglePlay = (): void => {
    if (this.isPaused) {
      this.player.play();
    } else {
      this.player.pause();
    }
  }

  toggleMute = (): void => {
    this.player.muted = !this.player.muted;
  }

  toggleFullscreen = (): void => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      if (this.isFakeFullscreen) {
        this.isFakeFullscreen = false;
      } else {
        try {
          this.videoContainer.requestFullscreen();
        } catch (e) {
          this.isFakeFullscreen = true;
        }
      }
    }
  }

  timeEvent = (): void => {
    this.currentTime = this.player.currentTime;
    this.elapsedTime = this.formatTime(this.currentTime);
    this.progress = this.currentTime / (this.duration / 100);
  }

  showControls = (): void => {
    this.controlsVisible = true;
    if (this.hidingControls) {
      clearTimeout(this.hidingControls);
      this.hidingControls = null;
    }
  }

  hideControls = (): void => {
    this.hidingControls = setTimeout(() => {
      this.controlsVisible = false;
    }, 2000);
  }

  formatTime(seconds: number): string {
    const time = new Date(Date.UTC(0, 0, 0, -1, 0, seconds));
    return Intl.DateTimeFormat('cs-CS', {hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(time);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.player) {
      if (changes.paused) {
        if (changes.paused.currentValue) {
          this.player.pause();
        } else {
          this.player.play();
        }
      }
      if (changes.seek) {
        this.seeking(changes.seek.currentValue);
      }
      if (changes.seeked) {
        this.finishSeeking();
      }
    }
  }
}
